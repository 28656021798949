export default class CustomerHelper {

    // eslint-disable-next-line
    static initApp() {

    }

    // eslint-disable-next-line
    static supportedRequest(type: string): boolean {
        return false;
    }

    // eslint-disable-next-line
    static handleRequest(data: any) {

    }

    // eslint-disable-next-line
    static async handleAsyncRequest(data: any): Promise<void> {

    }

    // eslint-disable-next-line
    static getLink(link: string) {
        return { link, target: '_blank' };
    }

    // eslint-disable-next-line
    static getDefaultUrlSuffix(): string {
        return "";
    }

    // eslint-disable-next-line
    static toGameOverRedirect() {

    }

}